<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col v-if="result.insertedId">
        <v-card>
          <v-card-title>
            Nieuwe API App aangemaakt
          </v-card-title>

          <v-card-text v-if="allLoaded">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <p>
                  De API app is aangemaakt.
                </p>

                <p>
                  <b>Let op</b> de API key wordt slechts eenmalig getoond
                  en zal niet meer te zien zijn als deze pagina verlaten wordt.
                </p>

                <v-simple-table>
                  <template #default>
                    <tbody>
                      <tr>
                        <td>Extern ID (App ID)</td>
                        <td>{{ result.apiApp.externalUuid }}</td>
                      </tr>

                      <tr>
                        <td>Sleutel (API Key)</td>
                        <td>{{ result.apiKey }}</td>
                      </tr>

                      <tr>
                        <td>Max requests per min</td>
                        <td>{{ result.apiApp.rateLimit }}</td>
                      </tr>

                      <tr>
                        <td>Organisatie</td>
                        <td>{{ result.organization.title }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  :to="{ name: 'apiApps' }"
                >
                  Terug
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-else>
        <v-card>
          <v-card-title>
            Nieuwe API App
          </v-card-title>

          <v-card-text v-if="allLoaded">
            <v-row v-if="sortedOrganizations && sortedOrganizations.length !== 1">
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="newApiApp.organizationId.value"
                  :error-messages="newApiApp.organizationId.errors"
                  :items="sortedOrganizations"
                  label="Organisatie"
                  clearable
                  @input="validateOrganization"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="validateAndCreate"
                >
                  Maak aan
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      createApiAppLoading: false,
      getApiAppLoading: false,
      newApiApp: {
        organizationId: {
          value: null,
          errors: [],
        },
      },
      organizations: {
        loaded: false,
        value: [],
      },
      result: {
        insertedId: null,
        apiApp: null,
        organization: null,
        apiKey: null,
      },
      breadcrumbs: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'API Apps',
          disabled: false,
          exact: true,
          to: { name: 'apiApps' },
        },
        {
          text: 'Nieuwe API App',
          disabled: true,
          exact: true,
          to: { name: 'apiAppsNew' },
        },
      ],
    };
  },
  computed: {
    allLoaded() {
      return !this.loading && this.organizations.loaded;
    },
    loading() {
      return this.createApiAppLoading || this.getApiAppLoading;
    },
    sortedOrganizations() {
      return [...this.organizations.value].sort((first, second) => {
        console.log();
        if (first.lowerCasedText > second.lowerCasedText) {
          return 1;
        }

        if (first.lowerCasedText < second.lowerCasedText) {
          return -1;
        }

        return 0;
      });
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'result.insertedId'(val) {
      if (val) {
        this.loadApiApp(val);
      }
    },
  },
  beforeMount() {
    this.loadOrganizations();
  },
  methods: {
    loadOrganizations() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.organizations.value = response
            .data
            .organizations
            .map((loadedOrganization) => ({
              text: loadedOrganization.title,
              lowerCasedText: loadedOrganization.title.toLowerCase(),
              value: loadedOrganization._id,
            }));

          this.organizations.loaded = true;
        });
    },
    loadApiApp(id) {
      const url = `${config.VUE_APP_API_BASE_URL}/api-apps/${id}`;

      this.getApiAppLoading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.result.apiApp = response.data.apiApp;

          if (response.data.organization) {
            this.result.organization = response.data.organization;
          }
        })
        .finally(() => {
          this.getApiAppLoading = false;
        });
    },
    createApiApp() {
      const url = `${config.VUE_APP_API_BASE_URL}/api-apps`;

      this.loading = true;

      const data = {
        organizationId: this.newApiApp.organizationId.value,
      };

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then((response) => {
          this.$store.commit('app/snackbarText', {
            text: 'De API App is aangemaakt',
            visible: true,
          });

          this.result.apiKey = response.data.apiKey;
          this.result.insertedId = response.data.insertedId;
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

          console.log(error.config);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateOrganization() {
      const newErrors = [];
      if (!this.newApiApp.organizationId.value) {
        newErrors.push('Is verplicht');
      }

      this.newApiApp.organizationId.errors = newErrors;
    },
    validateAndCreate() {
      this.validateOrganization();

      if (this.newApiApp.organizationId.errors.length > 0) return;

      this.createApiApp();
    },
  },
};
</script>
